// Logo.js
import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { Link, useStaticQuery, graphql } from "gatsby";

const LogoWrap = styled.div`
  margin: auto 0;
  flex: 0 1 36px;
  border-radius: 50%;
  border: solid 1px black;

  @media (max-width: 768px) and (orientation: landscape) {
    flex: 0 1 25px;
  }
`;

const ImgWrap = styled(Img)`
  border-radius: 50%;
`;
const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "alex" }, extension: { eq: "jpg" }) {
        childImageSharp {
          fluid(maxWidth: 50, pngQuality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <LogoWrap as={Link} to="/">
      <ImgWrap fluid={data.file.childImageSharp.fluid} alt="logo" />
    </LogoWrap>
  );
};

export default Logo;
