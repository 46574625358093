import React from "react";
import styled from "styled-components";
import Navbar from "../components/Navbar/Navbar";
import Transition from "../components/Transition/Transition";
import Footer from "../components/Footer/Footer";

const ContentWrapper = styled.div`
  margin: 0px 10%;

  @media (max-width: 768px) {
    margin: 0px 30px;
  }
`;

const DivWrapper = styled.div`
  min-height: 90vh;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export default function Layout({ children, location }) {
  return (
    <Transition location={location}>
      <PageWrapper>
        <DivWrapper>
          <Navbar />
          <ContentWrapper>{children}</ContentWrapper>
        </DivWrapper>
        <Footer />
      </PageWrapper>
    </Transition>
  );
}
