import React from "react";
import styled from "styled-components";

const WrapperFooter = styled.footer`
  text-align: center;
  margin: 5px 0;
`;
export default () => {
  return (
    <WrapperFooter>
      <span>&#169;</span> 2020 Alex Dhaenens
    </WrapperFooter>
  );
};
