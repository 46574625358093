// NavbarLinks.js

import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const NavItem = styled(Link)`
  text-decoration: none;
  color: black;
  font-family: Raleway, sans-serif;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;
  background-image: none;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: goldenrod;
    height: 1px;
    transition: all 0.4s ease-in;
  }

  :hover {
    color: goldenrod;
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 20px 0;
    font-size: 1.5rem;
    z-index: 6;
  }
`;
const NavbarLinks = () => {
  return (
    <>
      <NavItem to="/about">About me</NavItem>
      <NavItem to="/projects">Projects</NavItem>
      <NavItem to="/timeline">Timeline</NavItem>
      <NavItem to="https://dev.to/alexdhaenens">Blog</NavItem>
      <NavItem to="/contact">Contact</NavItem>
    </>
  );
};

export default NavbarLinks;
